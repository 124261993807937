<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
        height="80px"
      >
        <v-container fluid>
          <div class="d-flex">
            <v-checkbox
              v-model="enabled"
              label="By Month"
            />
            <v-spacer />
            <v-menu
              v-model="menu"
              lazy
              close-on-content-click
              min-width="100px"
            >
              <v-text-field
                slot="activator"
                v-model="monthOf"
                prepend-icon="event"
                :disabled="!enabled"
                readonly
              />
              <v-date-picker
                v-model="monthOf"
                :max="maxDate"
                :allowed-dates="allowedMonths"
                min="2015-01"
                type="month"
                year-icon="date_range"
              />
            </v-menu>
          &nbsp;&nbsp;&nbsp;&nbsp;
            <v-text-field
              v-model="search"
              :disabled="!enabled"
              append-icon="search"
              label="Search"
              clearable
            />
          </div>
        </v-container>
      </v-toolbar>
      <v-data-table
        v-if="enabled == false"
        :items="newsFeed"
        hide-headers
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <template>
            <td>
              <div class="card-container">
                <v-card width="1400">
                  <v-card-text>
                    <div
                      d-flex
                      flex-column
                    >
                      <h2 class="font-weight-black mb-10">
                        {{ props.item.title }}
                      </h2>
                    </div>
                    <div
                      class="my-4 text-subtitle-1 font-weight-medium text--secondary mb-10"
                    >
                      {{ props.item.entry }}
                    </div>
                    <div
                      class="text--primary text-justify mb-10"
                      v-html="props.item.content"
                    />
                  </v-card-text>
                </v-card>
              </div>
            </td>
          </template>
        </template>
      </v-data-table>
      <v-data-table
        v-else-if="enabled == true"
        :items="test"
        hide-headers
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td>
            <div class="card-container">
              <v-card width="1400">
                <v-card-text>
                  <div
                    d-flex
                    flex-column
                  >
                    <h2 class="font-weight-black mb-10">
                      {{ props.item.title }}
                    </h2>
                  </div>
                  <div
                    class="my-4 text-subtitle-1 font-weight-medium text--secondary mb-10"
                  >
                    {{ props.item.entry }}
                  </div>
                  <div
                    class="text--primary text-justify mb-10"
                    v-html="props.item.content"
                  />
                </v-card-text>
              </v-card>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>
<style>
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.text--primary {
  justify-content: center;
  align-items: center;
}
</style>
<script>
import { createGetParams } from '@/rest'
import { DateTime, Interval } from 'luxon'
import security from '@/security'

export default {
  components: {
    Panel: () =>
      import(/* webpackChunkName: "widepanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      colors: [
        'red',
        'pink',
        'purple',
        'deep-purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey',
      ],
      colorIndex: 0,
      breadcrumbs: [],
      search: null,
      newsFeed: [],
      // monthlyFeed: [],
      test: [],
      monthOf: null,
      month: null,
      // monthly: null,
      enabled: false,
      maxDate: '',
      menu: false,
      loading: false,
      title: null,
      today: DateTime.local()
        .setZone('Asia/Kuala_Lumpur')
        .toFormat('yyyy-LL-dd'),
    }
  },
  watch: {
    xox: function (val) {
      this.onex = !val
    },
    monthOf: function (val) {
      this.getMonthlyFeed(val)
    },
    search: function (val) {
      this.getMonthlyFeed(val)
    },
  },
  mounted: function () {
    this.getNewsFeed()
  },
  created: function () {
    let currentIndex = this.colors.length
    let color
    let randomIndex

    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex--)
      color = this.colors[currentIndex]
      this.colors[currentIndex] = this.colors[randomIndex]
      this.colors[randomIndex] = color
    }
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone(
        'Asia/Kuala_Lumpur',
      )
      const fromDate = DateTime.local(2015, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    hasRealmRole: function (role) {
      return security.roles(role)
    },
    cardClass (index) {
      return 'headline ' + this.color(index)
    },
    color (index) {
      return this.colors[index] + ' darken-2'
    },
    getNewsFeed: function () {
      const params = createGetParams(
        this.hasRealmRole(['dealer']) ? {} : { user: true },
      )
      this.$rest
        .get('getNewsFeed.php', params)
        .then(
          function (response) {
            this.newsFeed = response.data.feed
            // console.log(this.newsFeed)
          }.bind(this),
        )
        .catch((error) => {
          console.log(error)
        })
    },
    getMonthlyFeed: function () {
      const params = createGetParams({
        month: this.monthOf,
        search: this.search,
      })
      this.$rest.get('getMonthlyFeed.php', params)
        .then(
          function (response) {
            this.test = response.data.items
            console.log(this.test)
          }.bind(this),
        )
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
